.color-grad {
  background: linear-gradient(269.05deg, #2341ba 1.29%, #00599b 98.64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.yt-div iframe {
  display: inline;
  border-radius: 8px;
  overflow: hidden;
  width: 70%;
  height: 450px;
}

.yt-div .book {
  width: 30%;
  margin-left: -32px;
  display: inline-block;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 63, 111, 0.25);
  background: #ffffff;
  transform: translateY(-50%);
}

.trusted {
  margin-top: 2rem;
  padding: 2rem 0;
  background: linear-gradient(
    269.05deg,
    rgba(35, 65, 186, 0.05) 1.29%,
    rgba(0, 89, 155, 0.05) 98.64%
  );
}

.testimony {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 63, 111, 0.25);
  border-radius: 10px;
  height: 220px;
  margin: 15px;
}

.testimony img {
  height: 92px;
  width: 92px;
  border-radius: 50%;
}

.testimony div p:first-child::before {
  content: "“";
}

.testimony div p:first-child::after {
  content: "”";
}

.feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}

.feature img {
  width: 45%;
}

.feature > div {
  width: 45%;
}

.reverse {
  flex-direction: row-reverse;
}

.image-features {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/assets/images/country-landing/blur-bg.png");
  padding-top: 3rem;
}

.image-features h2 {
  font-weight: 600;
}

.lcr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lcr img {
  max-height: 576px;
  max-width: 550px;
  object-fit: cover;
}

.left,
.center,
.right {
  max-width: 365px;
}

.left div,
.right div {
  margin-bottom: 4rem;
}

.left {
  text-align: right;
  padding-left: 1.5rem;
}

.right {
  padding-right: 1.5rem;
}

.center {
  text-align: center;
  margin: 0 auto;
  padding-top: 3rem;
}

.get-started {
  padding: 3rem 15%;
  color: #ffffff;
  background: linear-gradient(269.05deg, #2341ba 1.29%, #00599b 98.64%), #d9d9d9;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
}

.get-started input {
  background: rgba(255, 255, 255, 0.35);
  border-radius: 6px;
  height: 52px;
  outline: none !important;
  border: none;
  color: #ffffff;
  padding: 0 136px 0 1rem;
  display: inline;
  width: 90%;
}

.get-started input:focus {
  box-sizing: none !important;
}

.get-started input::placeholder {
  color: #ffffff;
  font-size: 16px;
}

.get-started button {
  display: inline-block;
  margin-left: -129px;
}

@media screen and (max-width: 992px) {
  .yt-div iframe {
    width: 100%;
    height: calc(100vw * 0.5625);
  }

  .yt-div .book {
    display: block;
    position: static;
    width: 90%;
    margin: -2rem auto 0;
    transform: translate(0);
    padding: 2rem;
  }

  .left,
  .center,
  .right {
    max-width: 100%;
    padding: 0 1rem;
    text-align: center;
  }

  .left div,
  .center div,
  .right div {
    margin-bottom: 32px;
  }

  .center {
    padding-top: 3rem;
  }

  .left {
    order: 1;
  }

  .right {
    order: 2;
  }

  .lcr img {
    order: 3;
    max-height: unset;
    width: 80%;
  }

  .lcr {
    flex-direction: column;
  }

  .get-started {
    padding: 3rem 5%;
    flex-direction: column;
  }

  .get-started input {
    width: 100%;
  }

  .image-features h2 {
    font-size: 16px !important;
  }

  .center h2 {
    font-size: 22px !important;
  }

  .image-features p {
    font-size: 13px !important;
  }

  .center p {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .ads-page h2 {
    font-size: 18px !important;
  }

  .ads-page p {
    font-size: 15px !important;
  }

  .heading {
    font-size: 32px !important;
  }

  .heading br,
  .heading + p > br {
    display: none;
  }

  .testimony {
    padding: 1rem;
  }

  .testimony img {
    height: 64px;
    width: 64px;
    border-radius: 50%;
  }

  .feature {
    flex-direction: column;
  }

  .feature img {
    width: 100%;
    padding: 2.5rem 0 1.5rem;
  }

  .feature > div {
    width: auto;
  }

  .feature:not(:has(.reverse)) {
    flex-direction: column-reverse;
  }

  .get-started {
    padding: 3rem 1.5rem;
    flex-direction: column;
  }

  .image-features h2 {
    font-size: 16px !important;
  }

  .center h2 {
    font-size: 22px !important;
  }

  .image-features p {
    font-size: 13px !important;
  }

  .center p {
    font-size: 15px !important;
  }
}
