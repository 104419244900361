.left-side-image {
  background-image: url(../../../../../public/assets/images/auth/join-banner.jpg);
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  min-width: 100%;
}

.join-group-form {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-button {
  height: 50px;
  width: 400px;
  background: linear-gradient(268.75deg, #3e6588 3.84%, #2887af 100%);
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.sub-header {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #a2a2a2;
}

.main-header {
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  background: linear-gradient(268.75deg, #3e6588 3.84%, #2887af 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.ucode-input input {
  border: 1.5px solid #2887af;
  border-radius: 6px;
  height: 50px;
  width: 400px;
  padding: 20px;
}

.ucode-input input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-size: 12px;
}

.join-info {
  position: absolute;
  top: 0;
  right: 80px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(28px);
  border-radius: 6px;
  width: 458px;
  height: 169px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  backdrop-filter: blur(28px);
}

.grey-text-box {
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(100px);
  position: absolute;
  width: 550px;
  right: 40px;
  top: 50px;
}

.grey-text-box p {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
}

.overlayText-join-group {
  position: absolute;
  right: 20px;
  top: 60px;
  width: 400px;
  background-color: #2a86ae63;
  padding: 1rem;
  backdrop-filter: blur(10px);
  color: var(--bs-white);
  border-radius: 4px;
  font-size: 14px;
}

@media (min-width: 481px) and (max-width: 768px) {
  .continue-button {
    width: 100%;
  }
  .ucode-input input {
    width: 100%;
  }
  .join-group-form {
    display: flex;
    align-items: flex-start !important;
    justify-content: center;
  }
  .authFormCard {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .continue-button {
    width: 100%;
  }
  .ucode-input input {
    width: 100%;
  }
  .join-group-form {
    display: flex;
    align-items: flex-start !important;
    justify-content: center;
  }
  .authFormCard {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .continue-button {
    width: 100%;
  }
  .ucode-input input {
    width: 100%;
  }
  .join-group-form {
    display: flex;
    align-items: flex-start !important;
    justify-content: center;
  }
  .authFormCard {
    width: 100%;
  }
}
