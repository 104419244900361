.chevron {
  height: 7px;
  width: 10px;
  margin-left: 0.5rem;
  transition: transform 0.3s ease-out;
}

.chevron.up {
  transform-origin: center center;
  transform: rotate(180deg);
}

.dropdown-menu div {
  padding: 0 0.25rem 0.5rem;
}

.dropdown-menu div a {
  padding: 0.25rem 0.25rem 0.25rem 1.25rem !important;
  color: var(--text-primary) !important;
  font-weight: 500;
}

@media (min-width: 992px) {
  .dropdown {
    position: relative;
  }

  .dropdown-menu {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    z-index: -20;
    padding: 0;
  }

  .dropdown-menu.show {
    opacity: 1;
    z-index: 10;
  }

  .dropdown-menu div {
    width: 300px;
    padding: 1rem;
    background-color: var(--bg-tertiary);
    border-radius: var(--border-radius-secondary);
    box-shadow: -2px 2px 14px #00000040;
  }

  .dropdown-menu div a {
    padding: 0.5rem 1rem !important;
    font-weight: 600;
  }
}
