/* aboutus */

.description {
  font-weight: 500;
  line-height: 1.5;
  max-width: 75%;
}

.member img {
  width: 275px;
}

.member h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 8px 0 4px;
}

.member p {
  font-size: 12px !important;
  font-weight: 500;
}

/* pricing */

.pricingContainer {
  background: linear-gradient(269deg, #00599b 4.3%, #003b70 101.19%);
  padding: 4rem 0 5rem;
  color: white;
  font-weight: 500;
  user-select: none;
}

.pricingContainer a {
  font-weight: 600;
  border-radius: 8px;
  color: white !important;
  text-align: center;
}

.salesBtn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 16px;
  background: #0091ff52;
}

.tryBtn {
  padding: 13px 24px;
  background: #007ee6;
  box-shadow: 0px 2.76923px 10.38462px 0px #003f6f40;
  width: 100%;
  margin: 0 auto;
}

.muted {
  color: #ffffff99;
  margin: 0;
}

.plans {
  display: flex;
  position: relative;
}

.plansContainer {
  width: 60%;
}

.plan {
  padding-bottom: 2rem;
}

.plan img {
  width: 100%;
  max-width: 302px;
  margin: auto;
}

.dots li button::before {
  opacity: 0.75;
  color: var(--gray3);
}

.choose {
  width: 40%;
  margin: 3rem 0;
  margin-left: 1.25rem;
  padding: 2rem;
  border-left: 1px solid #0059a3;
}

.choose img {
  width: 75%;
}

.step {
  position: absolute;
  z-index: 10;
  border-radius: 46px;
  background: rgba(0, 145, 255, 0.32);
  padding: 10px 30px;
  font-weight: 600;
}

.step:first-of-type {
  top: 0.25rem;
  left: 0.75rem;
}

.step:last-of-type {
  bottom: 0.25rem;
  left: 0.75rem;
}

/* Get In Touch */

.contact {
  background: linear-gradient(
    269deg,
    rgba(35, 65, 186, 0.05) 1.29%,
    rgba(0, 89, 155, 0.05) 98.64%
  );
}

.contactForm {
  max-width: 450px;
}

.contactForm input,
.contactForm select,
.contactForm textarea {
  color: #3a3a3a !important;
  font-weight: 500;
  border: none;
  height: 50px;
  padding: 0 18px;
  border-radius: 6px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
  outline: none;
}

.contactForm textarea {
  height: 120px;
  resize: none;
}

.contactForm input:active,
.contactForm select:active,
.contactForm textarea:active,
.contactForm input:focus,
.contactForm select:focus,
.contactForm textarea:focus {
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15) !important;
}

.contactForm :global(.countryJson) {
  margin-top: 0 !important;
}

.contactForm :global(.countryJson) select {
  padding: 0 8px !important;
  width: 72px !important;
}

.contactForm :global(.showDropdown) label {
  width: calc(100% - 77px) !important;
}

@media screen and (max-width: 991px) {
  /* about */

  .description {
    max-width: 90vw;
    margin-top: 16px;
  }

  .member img {
    width: 165px;
  }

  /* pricing */
  .pricingContainer {
    padding: 2rem 0 3rem;
  }

  .salesBtn {
    position: static;
    display: block;
    margin: 0 auto 1.5rem;
    width: fit-content;
  }

  .plans {
    flex-direction: column;
    margin-bottom: 3rem;
  }

  .plan {
    padding-bottom: 0;
  }

  .plansContainer {
    border-left: 1px solid #0059a3;
    margin-left: 1.25rem;
    padding: 0 1.5rem 1.5rem;
    width: 100%;
  }

  .choose {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 0;
  }

  .step:last-of-type {
    bottom: -2.75rem;
  }

  /* features group */

  .feature button + button {
    font-weight: 500;
    font-size: 15px;
    height: 50px !important;
  }

  .feature button:has(+ button) {
    font-weight: 500;
    font-size: 15px;
    height: 50px !important;
  }
}
