.pricing-container {
  padding: 30px 12px 60px;
}

.pricing-container .pricing-header {
  display: none;
}

.pricing-container__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.pricing-container__text {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 1rem;
}

.pricing-container__text br {
  display: none;
}

.select-usertype .card img {
  height: 50px;
}

.get-connected-text {
  text-align: center;
}

.get-connected-text::after {
  content: "Be a part of our photo sharing community";
  display: block;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.user-details-form input,
.user-details-form .defaultInput {
  height: 42px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 14px;
  display: block;
  width: 100%;
  color: #000;
  box-shadow: none;
  box-shadow: none;
}

.user-details-form .defaultInput:focus {
  border: 1px solid rgba(0, 0, 0, 0.4);
  outline: 1px solid #000;
}

.user-details-form .countryJson {
  margin-top: 0;
  margin-bottom: 14px;
}

.user-details-form .selected {
  height: 42px !important;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 768px) {
  .pricing-container {
    margin: 0;
    max-width: 100% !important;
    padding: 30px 0 !important;
  }

  .pricing-container .pricing-wrapper {
    border-radius: 0;
  }

  .pricing-container__header {
    padding: 0 1rem;
  }

  .pricing-container__header div {
    flex-direction: column;
    align-self: flex-end;
    gap: 8px !important;
  }

  .pricing-container__header button {
    font-size: 12px !important;
    height: 36px;
  }

  .pricing-container__text {
    font-size: 18px;
    margin-bottom: 0.25rem;
  }

  .pricing-container__text + img {
    margin-bottom: 0;
  }

  .pricing-container__text br {
    display: block;
  }
}
