.title {
  color: #000000;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.modal-wrapper-closeIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

.modal-bordered-wrapper {
  border: 1px dashed var(--theme-primary);
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.qr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-align: center;
}

.closeIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0px;
  cursor: pointer;
  right: 0px;
}

.model-box {
  filter: drop-shadow(0px 2px 15px #3e658880);
  background: #fff;
  padding: 20px;
  position: relative;
}

.share-content {
  font-size: 15px;
  font-weight: 500;
  color: #00000099;
  margin-bottom: 8px;
}
.share-content-list {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.25rem;
}
.share-content-list li {
  margin-bottom: 4px;
}

.pro-tip {
  font-size: 12px;
  font-weight: 600;
  color: #2887af;
}

.pro-tip span {
  color: #3e6588;
}

.pro-tip img {
  height: 19px;
}

.tutorial {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 25px;
}

.tutorial img {
  height: 80px;
  cursor: pointer;
}

.tut-share {
  cursor: pointer;
}

.tut-share img {
  height: 15px;
}

.ua-store-links {
  display: flex;
}

.ua-store-links img {
  height: 52px;
  object-fit: cover;
}

.copy-button {
  border: 1px dashed var(--theme-primary) !important;
  transition: box-shadow 0.3s ease;
}

.copy-button:hover {
  box-shadow: 2px 2px 3px var(--theme-primary) !important;
}

@media screen and (max-width: 480px) {
  .tutorial p:first-of-type a {
    font-size: 14px !important;
  }

  .tutorial p:last-of-type {
    font-size: 10px !important;
  }

  .tutorial img {
    height: 70px;
  }

  .tut-share img {
    height: 15px;
  }
}
